import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { decodeToken } from "react-jwt";
import Modal from "react-bootstrap/Modal";
//import Carouselauto from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { ProgressBar } from "react-bootstrap";
import BounceLoader from "react-spinners/BounceLoader";
import axios from "axios";
import { Buffer } from "buffer";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Header from "../common/header";
import Footer from "../common/footer";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import configData from "../../config.json";

import MessageConst from "../../const/message.json";
import category from "../../const/category.js";
import { create as ipfsHttpClient } from "ipfs-http-client";
import PreviewFiletype from "../common/PreviewFiletype";
import fileArr from "../../const/filetypearray";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
// import { create as ipfsHttpClient } from 'kubo-rpc-client';
// import PulseLoader from "react-spinners/PulseLoader"
import { convertBase64, convertToFile } from "../../helper/convertBase64.js";

import { useDispatch, useSelector } from "react-redux";

// actions
import { createPaymentForIPFS } from "../../store/actions/wallet.js";
import { BeatLoader, ClockLoader } from "react-spinners";

const projectId = process.env.REACT_APP_INFURA_IPFS_PROJECT_ID;
const projectSecret = process.env.REACT_APP_INFURA_IPFS_PROJECT_SECRET;
const projectIdAndSecret = `${projectId}:${projectSecret}`;
// const authorization = 'Basic ' + btoa(projectId + ':' + projectSecret);

const client = ipfsHttpClient({
	host: configData.HOST_IPFS,
	port: configData.PORT_IPFS,
	protocol: configData.PROTOCOL_IPFS,
	// headers: { authorization }
	headers: {
		authorization: `Basic ${Buffer.from(projectIdAndSecret).toString(
			"base64"
		)}`,
	},
});


const MAX_COUNT = 1000;

const Createnft = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [formData, setFormData] = useState({});
	const [urlArr, setUrlArr] = useState([]);
	const [file, setFile] = useState(null);
	// const [finalFile, setFinalFile] = useState([]);
	const [fileLimit, setFileLimit] = useState(false); // eslint-disable-line
	// const [fileType, setFileType] = useState(null);
	const [loading, setLoading] = useState(false);
	const [progress, setProgress] = useState(0);
	const [searchKey, setSearchKey] = useState(true);
	const [showModel, setShowModel] = useState(false);
	const [formValues, setFormValues] = useState([
		{ traitType: "", traitValue: "" },
	]);
	const [oneByOneImageUrl, setOneByOneImageUrl] = useState(null);
	const [finalMetaDataArray, setFinalMetaDataArray] = useState([]);
	const [nameIndex, setNameIndex] = useState(null);
	const [fileName, setFileName] = useState(null);
	const [initialize, setInitialize] = useState(false)
	const [loader, setLoader] = useState(true)
	const [isPayment, setIsPayment] = useState(false)
	const [isPaymentModal, setIsPaymentModal] = useState(false);
	const [bufferFiles, setBufferFiles] = useState([]);
	const [currencyToPay, setCurrencyToPay] = useState("");
	const [totalAmount, setTotalAmount] = useState("");
	const [isLoader, setIsLoader] = useState(false)
	const [isActive, setisActive] = useState(false)
	const [totalPercentage, setTotalPercentage] = useState(false)
	const [isCalled, setIsCalled] = useState(false)
    const [loaderForBase64, setLoaderForBase64] = useState(false)


	const token = localStorage.getItem("jwtToken");
	const myDecodedToken = decodeToken(token);
	// console.log(myDecodedToken, "sdfasdfasf")
	// const CHUNKSIZE = 4 * 1024 * 1024; // 4MB
	/// 2097152;
	// 3 * 1024 * 1024; // 3MB


	// states
	const { paymentInfo, error } = useSelector((state) => state.IPFSPaymentReducer);

	// console.log(paymentData, "paymentData");

	const tooltip = (
		<Tooltip id="tooltip">
			<strong>Add Metadata</strong>
		</Tooltip>
	);
	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData((p) => ({
			...p,
			[name]: value.trim(),
		}));
	};

	function getFileType(file) {
		if (file.type) {
		  return file.type; // MIME type (if available)
		}
	  
		// Fallback to file extension
		const extension = file.name.split('.').pop().toLowerCase();
		return extension;
	  }

	const handleFileEvent = (e) => {
		e.preventDefault();
		setLoaderForBase64(true)
		setIsLoader(true)
		const chosenFiles = Array.prototype.slice.call(e.target.files);
		const checkFiles = chosenFiles.filter(
			(files) => !fileArr.includes(getFileType(files))
		);
		

		if (checkFiles?.length > 0) {
			e.target.value = "";
			toast.warn(MessageConst.warningSelectProperFileFormat, {
				toastId: "createNft4dg",
				onClose: () => {
					setLoaderForBase64(false)
					setIsLoader(false)
				}
			});
		} else {
			const filterWithType = chosenFiles.map((file) => {
				if (!file.type) {
					return new File([file], file.name, {
						type: getFileType(file),
					});
				}
				return file;
			})
			// console.log("check the checkFiles", filterWithType)
			
			retrieveFile(filterWithType);
		}
	};

	const retrieveFile = async (chosenFiles) => {
		const uploaded = [];
		let limitExceed = false;
		chosenFiles.map((files) => { // eslint-disable-line
			if (chosenFiles.findIndex((f) => f.name === files.name) === -1) {
				toast.warn(MessageConst.SAME_NAME_FILE_ERROR, {
					toastId: "samename1",
					onClose: () => {
						setIsLoader(false)
						setLoaderForBase64(false)
					}
				});
				return true;
			}
			if (chosenFiles.length === MAX_COUNT) setFileLimit(true);
			if (chosenFiles.length > MAX_COUNT) {
				toast.warn(MessageConst.MAX_FILE_UPLOAD_WARN, {
					toastId: "maxlimit",
					onClose: () => {
						setIsLoader(false)
						setLoaderForBase64(false)
					}
				});
				setFileLimit(false);
				limitExceed = true;
				return true;
			}
			uploaded.push(files);
		}); // eslint-disable-line
		

		// start from here
		if (!limitExceed) {
			const filesData = uploaded.map(vl => ({ url: vl, ftype: vl.type.split("/")[1], ctype: vl.type.split("/")[0] }));
			const fileToBase64 = filesData.map(async (vl, i) => ({ ...vl, _id: ++i, url: await convertBase64(vl.url) }));
			try {				
				await Promise.all(fileToBase64).then(res => {				
					setBufferFiles(res);
				}).then(() => {					
					setLoaderForBase64(false)
					setIsLoader(false)
				});
			} catch (error) {
				console.log(error, "error on base64")
			}
		}
	};

	const readBuffer = (values) => {
		const reader = new window.FileReader();
		return new Promise((resolve) => {
			reader.readAsArrayBuffer(values);
			reader.onloadend = () => {
				resolve(Buffer(reader.result))
			}
		})
	}

	useEffect(() => {
		if (isPayment && totalPercentage) {
			if(file.length === urlArr.length){
				setTimeout(() => { 
					setIsPaymentModal(false)
					setIsLoader(false)
				}, 1000)
			}
		}
	}, [isPayment, file, urlArr, totalPercentage])

	useEffect(() => {
		let isMounted = true;
		if (!!paymentInfo && isActive && isMounted) {
			const { message } = paymentInfo;
			toast.success(message, {
				toastId: "paymentsuccess",
				onClose: async () => {
					const data = bufferFiles.map(async vl => await convertToFile(vl.url, `image_${bufferFiles.indexOf(vl)}`));
					const fileData = await Promise.all(data);
					setFile(fileData);
					setIsCalled(true)
					// setIsLoader(true);
					setIsPayment(true);
					setisActive(false);
				}
			})
			isMounted = false
		}
	}, [paymentInfo, isActive])


	useEffect(() => {		
		let isMounted = true;		
		if (!!error && isActive) {
			const { message } = error;
			toast.error(message, {
				toastId: "paymenterror",
				onClose: () => {
					if (isMounted) {
						setIsLoader(false)
						setisActive(false)
						setIsPaymentModal(false)
						setIsPayment(false);
					}
				}
			});
		}
		return () => {
			isMounted = false;
		}
    }, [error, isActive])	

	// console.log(paymentData, "paymentData");
	// const memoizedFunction = React.useCallback(
	// 	async (file) => {
	// 		if (file !== null) {
	// 			setUrlArr([]);
	// 			// try {
	// 				// let percentage = 0;
	// 				const finalFiles = await Promise.all(
	// 					Array.from(file).map(async (singlefile) => {
	// 						setInitialize(true)
	// 						// const key = await readBuffer(singlefile)
	// 						setInitialize(false)						
	// 						setFileName(singlefile.name);
	// 						const result = await client.add(singlefile, {
	// 							pin: true,
	// 							progress: (progress) => setProgress(Math.floor((progress / singlefile.size) * 100))
	// 						});
	// 						// setTotalPercentage(Math.floor(((++percentage / file.length) * 100)));
	// 						const url = `${configData.ipfs_p}${result.path}`;
	// 						return { url, ftype: singlefile.type.split("/")[1], ctype: singlefile.type.split("/")[0] };
	// 					})
	// 				);
	// 				console.log(finalFiles, "finalFiles")
	// 				setUrlArr(finalFiles);
	// 				setProgress(0);
	// 				// setTotalPercentage(0);
	// 				setFileName(null);
	// 			// } catch (err) {
	// 			// 	console.log(err, "chunks")
	// 			// }
	// 		}
	// 	},
	// 	[file]
	// );

	// useEffect(() => {
	// 	memoizedFunction(file);
	// }, [memoizedFunction, file]);  // eslint-disable-line
		
    useEffect(() => {
		if (!!file && file.length > 0 && isCalled) {		   
			// console.log(file, "file")		
			setIsLoader(true)
			const finalFiles = [];
			// let i = 0;
			const promises = file.map(async (file1, i) => {
				setProgress(1)
				setFileName(file1.name);
				// console.log(file1, "file1")
				try {
					const result = await client.add(file1, {
						chunker: file1.size > 1048576 ? 'size-1048576' : undefined,  // 1 MB chunk size,
						pin: true,
						progress: (prog) => setProgress(Math.floor((prog / file1.size) * 100)),
					});
					const url = `${configData.ipfs_p}${result.path}`;
					finalFiles.push({ url, _id: ++i, ftype: file1.type.split("/")[1], ctype: file1.type.split("/")[0] });
					// setTotalPercentage(Math.floor(((i + 1) / file.length) * 100)); // Calculate percentage based on i
				} catch (error) {
					console.error('Error uploading file: ', error);
				}
				// i++;
			});
			Promise.all(promises).then(() => {
				const finalData = bufferFiles.map(x => finalFiles.find(a2 => a2._id === x._id));
				// console.log(finalData, "finalData")
				setUrlArr(finalData);
				setFileName(null);
				setIsLoader(false)
				setTotalPercentage(true);
				setIsCalled(false)
			});
		}
	}, [isCalled]);


	let handleaddChange = (i, e) => {
		let newFormValues = [...formValues];
		newFormValues[i][e.target.name] = e.target.value.trimStart();
		setFormValues(newFormValues);
	};

	let addFormFields = () => {
		setFormValues([...formValues, { traitType: "", traitValue: "" }]);
	};

	let removeFormFields = (i) => {
		let newFormValues = [...formValues];
		newFormValues.splice(i, 1);
		setFormValues(newFormValues);
	};


	const handlePayment = () => {
		setisActive(true)
		// console.log(currencyToPay, "currencyToPay")
		if (currencyToPay === "") {
			return toast.error(MessageConst.WARN_CURRENCY_NOT_FOUND, {
				toastId: "currencyCheck",
				onClick: () => setisActive(false)
			});
		}

		const data = {
			account: myDecodedToken.ac,
			amount: totalAmount.toString(),
			issuedToken: myDecodedToken.it,
			currency: currencyToPay
		}
		dispatch(createPaymentForIPFS({ data, loader: true }));
		return;
	}


	let handleSubmit = async (event) => {
		event.preventDefault();
		// const checkEmpty = formValues.every(
		// 	(item) => item.traitType && item.traitValue
		// );
		// if (!checkEmpty) {
		// 	return toast.warn(MessageConst.WARN_METADATA, {
		// 		toastId: "metaCheck",
		// 	});
		// }



		const obj = {
			name: nameIndex,
			type: formData.category,
			tokentaxon: formData.tokentaxon ? formData.tokentaxon : 0,
			description: formData.description,
			externalurl: formData.externalurl,
			creator: myDecodedToken.ac,
			image: oneByOneImageUrl.split("_")[0],
			attributes: formValues,
		};

		const metadata = urlArr?.length > 1 ? { ...obj, collectionName: formData.collectionName } : obj;
		
		const created1 = await client.add(JSON.stringify(metadata));
		
		const urlMetadata = `${configData.ipfs_p}${created1.path}`;
		const filteredUrl = urlArr.map((v, i) => ({...v, url: urlArr.length > 1 ? `${v.url}_${i++}` : `${v.url}_${null}`}))
		console.log(filteredUrl, "filteredUrl", oneByOneImageUrl)
		const allFilteredUrl = filteredUrl.map((vl) => {
			console.log(vl.url, "urlArr112", oneByOneImageUrl);
			if (vl.url == oneByOneImageUrl) {
				return { ...vl, metaDataUrl: urlMetadata };
			} else {
				return vl;
			}
		});
		
		const finalUrlArr = allFilteredUrl.map(vll => ({ ...vll, url: vll.url.split("_")[0] }))
		console.log(finalUrlArr, "urlMetadata")
		setUrlArr(finalUrlArr);
		setFinalMetaDataArray([...finalMetaDataArray, urlMetadata]);
		setFormValues([{ traitType: "", traitValue: "" }]);
		setShowModel(false);
	};

	const validation = () => {
		let decimalregex = /^\d{0,7}(\.\d{0,6})?$/;

		if (urlArr.length > 1 && !formData["collectionName"]) {
			toast.warn(MessageConst.warningEnterNftCollection, {
				toastId: "createNft10",
			});
			return;
		}

		if (!formData["name"]) {
			toast.warn(MessageConst.warningEnterNftName, {
				toastId: "createNft1",
			});
			return;
		}

		if (!formData["category"]) {
			toast.warn(MessageConst.SelectCategory, {
				toastId: "createNft4",
			});
			return;
		}

		if (!formData["price"]) {
			toast.warn(MessageConst.warningEnterPrice, {
				toastId: "createNft3",
			});
			return;
		}
		if (formData["price"] <= 0) {
			toast.warn(MessageConst.warningEnterPrice, {
				toastId: "createNft3",
			});
			return;
		}
		if (!decimalregex.test(formData["price"])) {
			toast.warn(MessageConst.XRP_WRONG_DECIMAL, {
				toastId: "wrongdecimal11",
			});
			return;
		}
		if (!formData["description"]) {
			toast.warn(MessageConst.warningNFTDiscription, {
				toastId: "createNft4",
			});
			return;
		}

		if (!formData["externalurl"]) {
			toast.warn(MessageConst.warningExternalurl, {
				toastId: "createNft777",
			});
			return;
		}

		return true;
	};

	const getMetadataWithUrls = async (ipfsImageUrls) => {
		const obj = {
			type: formData.category,
			tokentaxon: formData.tokentaxon ? formData.tokentaxon : 0,
			description: formData.description,
			externalurl: formData.externalurl,
			creator: myDecodedToken.ac,
			attributes: formValues,

		};
		// console.log(ipfsImageUrls.length, "check the lenght")
		if (ipfsImageUrls.length > 1) {
			const finalImages = ipfsImageUrls.map(async (vl, i) => {
				const metadata = {
					collectionName: formData.collectionName,
					name: formData.name + `#${i + 1}`,
					image: vl.url,
					...obj
				};
				const created1 = await client.add(JSON.stringify(metadata));
				const urlMetadata = `${configData.ipfs_p}${created1.path}`;
				return ({ ...vl, metaDataUrl: urlMetadata });
			});
			// console.log(await Promise.all(finalImages), "finalimages")
			return await Promise.all(finalImages);
		} else {
			const metadata = {
				// collectionName: formData.collectionName,
				name: formData.name,
				image: ipfsImageUrls[0].url
			};
			const created1 = await client.add(JSON.stringify(metadata));
			const urlMetadata = `${configData.ipfs_p}${created1.path}`;
			// console.log([{ ...ipfsImageUrls[0], metaDataUrl: urlMetadata }], "finalimages111");
			return [{ ...ipfsImageUrls[0], metaDataUrl: urlMetadata }]
		}

	}


	const handleCurrency = (e) => {
		const { value } = e.target;

		if (value) {
			const total = value.split(" ")[1] * bufferFiles.length;
			setCurrencyToPay(value.split(" ")[0]);
			setTotalAmount(total)
		} else {
			setTotalAmount("")
			setCurrencyToPay("")
		}
	}

	const createPayment = () => {
		try{
			console.log("check the validation")
			if (validation()) {
				console.log("check the validation done")
				if (bufferFiles.length === 0) {
					console.log("check the bufferFiles", bufferFiles.length)
					toast.warn(MessageConst.warningSelectFile, {
						toastId: "createNft2",
					});
					return;
				}
				console.log("check the isPaymentModal", isPaymentModal)
				setIsPaymentModal(!isPaymentModal)
			}
		}catch(e){
			console.log(e, "create payment error")
		}
	}

	const createNft = async (e) => {
		setIsLoader(true)
		e.preventDefault();
		// if (validation()) {
		// if (!file ?? file.length === 0) {
		// 	toast.warn(MessageConst.warningSelectFile, {
		// 		toastId: "createNft2",
		// 	});
		// 	return;
		// }

		const arrImg = await new Promise(async resolve => {
			if (finalMetaDataArray.length === 0) {
				const ImgArray = await getMetadataWithUrls(urlArr);
				resolve(ImgArray);
			} else {
				resolve([]);
			}
		});

		try {
			const config = {
				headers: {
					Authorization: `Basic ${token}`,
				},
			};
			const obj = {
				name: formData.name,
				category: formData.category,
				tokentaxon: formData.tokentaxon,
				image: arrImg.length ? arrImg : urlArr,
				price: formData.price,
				description: formData.description,
				externalurl: formData.externalurl,
				status: "created",
				fileType: arrImg.length ? arrImg : urlArr,
				contentType: arrImg.length ? arrImg : urlArr,
			};

			const data = urlArr.length > 1 ? { ...obj, collectionName: formData.collectionName } : obj;

			let res = await axios.post(
				`${configData.LOCAL_API_URL}nft/createNft`,
				data,
				config
			);
			if (res.data.success) {
				toast.success(res.data.message, {
					toastId: "createNft5",
				});
				setTimeout(() => {
					setIsLoader(false)
					navigate("/MyNFT");
				}, 2000);
			}
		} catch (error) {
			toast.error(error.response.data.message, {
				toastId: "createNft6",
			});
		}
		// }
	};

	const openModal = (imageUrl, i) => {		
		if (validation(formData)) {
			setShowModel(true);
			setOneByOneImageUrl(`${imageUrl}_${i}`);
			const nameImg = i === null ? formData.name : formData.name + `#${i}`;
			setNameIndex(nameImg);
		}
	};
	const handleCloseModel = () => setShowModel(false);

	return (
		<React.Fragment>
			<Header setSearchKey={setSearchKey} />
			{searchKey && (
				<div className="gradientBg createNewNFT">

					<>
						<Container className="content-container">
							<Row className="auth-wrapper App align-items-center padding-top-bottom-50">
								<Col xs={12} md={10}>
									<Form className="auth-inner">
										<h3>Create NFT</h3>
										<Form.Group className="mb-3" controlId="formBasicFirstName">
											<Form.Label>Collection</Form.Label>
											<Form.Control
												type="text"
												name="collectionName"
												onChange={handleChange}
												placeholder="Enter Collection"
											/>
										</Form.Group>
										<Form.Group className="mb-3" controlId="formBasicFirstName">
											<Form.Label>Name</Form.Label>
											<Form.Control
												type="text"
												name="name"
												onChange={handleChange}
												placeholder="Enter Name"
											/>
										</Form.Group>
										<Form.Group className="mb-3" controlId="formBasicFirstName">
											<Form.Label>Category</Form.Label>
											<Form.Select
												onChange={handleChange}
												name="category"
												aria-label="Default select example"
											>
												<option value="">Select Category</option>
												{category.map((cat, index) => (
													<option key={index} value={cat}>
														{cat}
													</option>
												))}
											</Form.Select>
										</Form.Group>
										<Form.Group className="mb-3" controlId="formBasicLastName">
											<Form.Label>Token Taxon</Form.Label>
											<Form.Control
												type="number"
												name="tokentaxon"
												onChange={handleChange}
												placeholder="Token Taxon"
											/>
										</Form.Group>
										<Form.Group className="mb-3" controlId="formBasicLastName">
											<Form.Label>Floor Price (XRP)</Form.Label>
											<Form.Control
												type="number"
												name="price"
												onChange={handleChange}
												placeholder="Enter Price"
											/>
										</Form.Group>
										<Form.Group className="mb-3" controlId="formBasicEmail">
											<Form.Label>Description</Form.Label>
											<Form.Control
												as="textarea"
												rows={5}
												name="description"
												onChange={handleChange}
												placeholder="Enter Description"
											/>
										</Form.Group>
										<Form.Group className="mb-3" controlId="formBasicEmail">
											<Form.Label>External Url</Form.Label>
											<Form.Control
												type="text"
												name="externalurl"
												onChange={handleChange}
												placeholder="Enter Any URL of your choice"
											/>
										</Form.Group>
										<Form.Group className="mb-3" controlId="formBasicFirstName">
											{/* <Form.Label>{initialize ? "loading..." : !!fileName ? `File Name : ${fileName}` : urlArr.length > 0 ? (urlArr.length === 1 ? `${urlArr.length} file uploaded successfully !` : `${urlArr.length} files uploaded successfully!`) : "Image"}</Form.Label> */}
											{/* {progress > 0 && (urlArr.length > 0 && bufferFiles.length !== urlArr.length) && (
												<ProgressBar
													now={progress}
													animated
													label={`${progress} bytes uploaded`}
												/>
											)} */}
											<Form.Control
												type="file"
												name="file"
												onChange={handleFileEvent}
												multiple
												disabled={urlArr.length > 0 ? true : false}												
											/>
											<Form.Label>
												Select png, gif, jpg, jpeg, mpeg, ogg, mp4, pdf files.
											</Form.Label>
										</Form.Group>
										{urlArr.length > 0 && <p>Add Metadata</p>}										
										{loaderForBase64 ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '10vh', marginTop: '-26px', paddingBlock: '1rem' }}>
											<ClockLoader size={50} color="#c000ff" boarderWidth={5} loading={true} />
											</div> : 
										<div className="selfCrousel">																				 
											{(urlArr.length > 0 ? urlArr : bufferFiles).length !== 0 ? (
												(urlArr.length > 0 ? bufferFiles.map(x => urlArr.find(a2 => a2._id === x._id)) : bufferFiles)?.map((val, i) => {
													return (
														<div className="img_preview_file" key={i}>				
														  	{urlArr.length > 0 && (val?.metaDataUrl ? (
																<i
																	aria-hidden="true"
																	className="fa fa-minus faPlusIcan"
																/>
															) : (
																<div className="metadata-cls">
																	<OverlayTrigger
																		placement="right"
																		overlay={tooltip}
																	>
																		<i
																			aria-hidden="true"
																			className="fa fa-plus faPlusIcan"
																			onClick={() => {
																				openModal(
																					val.url,
																					urlArr.length > 1 ? i : null
																				);
																			}}
																		/>
																	</OverlayTrigger>
																	<span className="circle-icon">{val._id}</span>
																</div>
															))}{" "}
															{/* {console.log(val.metaDataUrl, "check", ++i)} */}
															{/* {val.metaDataUrl == undefined ?  : ""} */}
															<PreviewFiletype
																key={i}
																fileType={val.ctype}
																image={val.url}
																setLoader={setLoader}
															/>
															{/* } */}
															<br />
														</div>
													);
												})
											) : <p></p>}
											{/* <div className="loader_position">
												<BounceLoader
													sizeUnit="px"
													size={60}
													color="#000000"
													loading={loading}
												/>
											</div> */}
										</div>}
										<Button
											variant="primary"
											onClick={isPayment ? createNft : createPayment}
											type={isPayment ? "submit" : "button"}
											className="form-control"
											disabled={isLoader}
										> {isLoader ? (
											<BeatLoader
												sizeUnit="px"
												size={10}
												color="#FFF"
												loading
											/>
										) : ("Create")}
										</Button>
									</Form>
								</Col>
							</Row>

							{/* modal start*/}
							<Modal show={showModel} onHide={handleCloseModel} backdrop="static">
								<Modal.Header closeButton>
									<h6>Add Properties for metadata.</h6>
								</Modal.Header>
								<Modal.Body>
									<Row>
										<Col xs={12} md={12}>
											{formValues.map((element, index) => (
												<div className="form-inline" key={index}>
													<Row>
														<Col xs={5} md={5}>
															<label htmlFor="Trait Type">Trait Type</label>
															<input
																type="text"
																name="traitType"
																value={element.traitType || ""}
																className="form-control"
																onChange={(e) => handleaddChange(index, e)}
															/>
														</Col>
														<Col xs={5} md={5}>
															<label htmlFor="Value">Value</label>
															<input
																type="text"
																name="traitValue"
																value={element.traitValue || ""}
																className="form-control"
																onChange={(e) => handleaddChange(index, e)}
															/>
														</Col>
														<Col xs={2} md={2}>
															{index ? (
																<>
																	<br />
																	<i
																		className="fa fa-close"
																		onClick={() => removeFormFields(index)}
																		style={{ fontSize: "36px" }}
																		aria-hidden="true"
																	></i>
																</>
															) : null}
														</Col>
													</Row>
												</div>
											))}
										</Col>
									</Row>
									<br />
									<Row>
										<Col xs={12} md={12}>
											<Button
												variant="primary"
												type="submit"
												className=""
												onClick={() => addFormFields()}
											>
												Add More
											</Button>
										</Col>
									</Row>
									<br />
									<Row>
										<Col xs={12} md={12} className="margin-top1">
											<Button
												variant="primary"
												type="submit"
												className="form-control margin-top1"
												onClick={(e) => handleSubmit(e)}
											>
												Save
											</Button>
										</Col>
									</Row>
								</Modal.Body>
								<br />
							</Modal>
							{/* modal end */}

							<Modal
								show={isPaymentModal}
								onHide={() => {
									setIsPaymentModal(!isPaymentModal)
									setTotalAmount("")
									setCurrencyToPay("")
								}}
								className="nftDetailModal"
								backdrop={ isLoader ? "static" : "false"}
							>
								<Modal.Body>
									<Row>
										<Col xs={12} md={12}>
											<div className="img-center">
												<h3 className="text-center">Create Payment</h3>
												<h6>Choose Currency to Process IPFS Payment.</h6>
												{isPayment && progress > 0 && (
													<>
													<Form.Label>{initialize ? "loading..." : !!fileName ? `File Name : ${fileName}` : urlArr.length > 0 ? (urlArr.length === 1 ? `${urlArr.length} file uploaded successfully !` : `${urlArr.length} files uploaded successfully!`) : "Image"}</Form.Label>
													<ProgressBar
															now={progress}
															animated
															label={`${progress}% completed`}
														/>													
														<p>Please wait while your files are being uploaded in an IPFS...</p>
													</>
												)}
											</div>
										</Col>
									</Row>
									<br />
									<Row>
										<Col xs={6} md={6}>
											<Form.Select
												onChange={handleCurrency}
												name="Currency"
												aria-label="Default select example"
												id="currency-dropdown"
											>
												<option value="">Select Currency</option>
												<option value={`XRP ${0.1}`}>XRP</option>
												<option value={`XDX ${100}`}>XDX</option>
												<option value={`5853515541440000000000000000000000000000 ${0.01}`}>XSQUAD</option>
											</Form.Select>
										</Col>
										<Col xs={6} md={6}>
											<input
												type="text"
												name="saleAmount"
												value={totalAmount}
												// onChange={handleAddTokenAmount}
												className="form-control"
												placeholder="Amount"
												disabled
											></input>
										</Col>
									</Row>
									<br />
									<br />
									<Row>
										<Col xs={12} md={12}>
											<Button
												variant="primary"
												onClick={handlePayment}
												type="button"
												className="form-control"
												disabled={isLoader ? true : false}
											>
												{isActive ? (
													<BeatLoader
														sizeUnit="px"
														size={10}
														color="#FFF"
														loading
													/>
												) : (
													"Payment"
												)}
											</Button>
										</Col>
									</Row>
								</Modal.Body>
								<br />
							</Modal>
						</Container>
					</>

				</div>
			)}
			<Footer />
			<ToastContainer />
		</React.Fragment>
	);
};

export default Createnft;
