import { actionTypes } from "../actionTypes/wallet";
import {
	walletConnect,
	accountDetail,
	getBalanceDetail,
	registrationFeeApi,
	walletDisConnect,
	createPayment
} from "../services/wallet";

/////////////////// QR CODE start////////////////

export let connectWalletAction = (data) => async (dispatch) => {
	dispatch({ type: actionTypes.WALLET_CONNECT_REQUEST, loader: data.loader });
	const payload = await walletConnect(data.formDataInput);
	const { status } = payload;
	if (status === 200) {
		dispatch({
			type: actionTypes.WALLET_CONNECT_SUCCESS,
			payload: payload.data,
		});
	} else {
		dispatch({ type: actionTypes.WALLET_CONNECT_FAILURE, payload });
	}
};
/////////////////// QR CODE END////////////////

/////////////////// ACCOUNT DETAIL start////////////////
export let accountDetailAction = (data) => async (dispatch) => {
	dispatch({ type: actionTypes.ACCOUNT_DETAIL_REQUEST, loader: data.loader });
	const payload = await accountDetail(data.data);
	const { status } = payload;
	if (status === 200) {
		dispatch({
			type: actionTypes.ACCOUNT_DETAIL_SUCCESS,
			payload: payload.data,
		});
	} else {
		dispatch({ type: actionTypes.ACCOUNT_DETAIL_FAILURE, payload });
	}
};

/////////////////// ACCOUNT DETAIL END////////////////

/////////////////// GET BALANCE start////////////////
export let getBalanceAction = (data) => async (dispatch) => {
	dispatch({ type: actionTypes.GET_BALANCE_REQUEST, loader: data.loader });
	const payload = await getBalanceDetail(data.data);
	const { status } = payload;
	if (status === 200) {
		dispatch({ type: actionTypes.GET_BALANCE_SUCCESS, payload: payload.data });
	} else {
		dispatch({ type: actionTypes.GET_BALANCE_FAILURE, payload });
	}
};

/////////////////// GET BALANCE END////////////////

/////////////////// Registration Charge////////////////
export let registrationFee = (data) => async (dispatch) => {
	dispatch({ type: actionTypes.REGISTRATION_FEE_REQUEST, loader: data.loader });
	const payload = await registrationFeeApi(data.data);
	const { status } = payload;
	if (status === 200) {
		dispatch({
			type: actionTypes.REGISTRATION_FEE_SUCCESS,
			payload: payload.data,
		});
		return payload.data;
	} else {
		dispatch({ type: actionTypes.REGISTRATION_FEE_FAILURE, payload });
		return payload.data;
	}
};
/////////////////// Registration Charge////////////////

/////////////////// Disconnect wallet START////////////////
export let disConnectWalletAction = (data) => async (dispatch) => {
	dispatch({ type: actionTypes.WALLET_DISCONNECT_REQUEST, loader: data.loader });
	const payload = await walletDisConnect(data.formDataInput);
	const { status } = payload;
	if (status === 200) {
		dispatch({
			type: actionTypes.WALLET_DISCONNECT_SUCCESS,
			payload: payload.data,
		});
	} else {
		dispatch({ type: actionTypes.WALLET_DISCONNECT_FAILURE, payload });
	}
};
/////////////////// Disconnect wallet end ////////////////


// create payment for IPFS accound based on total nft count

export const createPaymentForIPFS = (data) => async (dispatch) => {
	dispatch({ type: actionTypes.CREATE_PAYMENT_REQUEST, loader: data.loader });
	
	const payload = await createPayment(data.data);
	const { status } = payload;
	if (status === 200) {
		dispatch({
			type: actionTypes.CREATE_PAYMENT_SUCCESS,
			payload: payload.data,
		});
	} else {
		dispatch({ type: actionTypes.CREATE_PAYMENT_FAILURE, payload });
	}
}